import React, { Component } from 'react'
import { Link } from 'gatsby'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBContainer, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import NavLogo from './navLogo'

class NavBarPage extends Component {
  state = {
    collapsed: false,
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  componentDidMount() {
    const str = window.location.pathname.substr(
      1,
      window.location.pathname.length - 1
    )
    const len = str.indexOf('/')
    this.setState({
      parentNav: len < 0 ? str : str.substr(0, len),
    })
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.handleTogglerClick}
      />
    )

    const isActive = ({ isCurrent }) => {
      return isCurrent ? { className: 'nav-link active' } : {}
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent ? { className: 'nav-link active' } : {}
    }

    return (
      <div>
        <MDBNavbar
          color="top-nav-collapse"
          dark
          expand="lg"
          fixed="top"
          scrolling
          transparent
        >
          <MDBContainer>
            <MDBNavbarBrand>
              <Link to="/" aria-current="page" aria-label="Link to home">
                <NavLogo />
              </Link>
            </MDBNavbarBrand>
            <MDBNavbarToggler aria-label="Mobile Navigation Button" onClick={this.handleTogglerClick} />
            <MDBCollapse isOpen={this.state.collapsed} navbar>
              <MDBNavbarNav right>
              <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'methodology' ? 'active' : '' }>
                      <span className="mr-1">Methodology</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                      <MDBDropdownItem href="/methodology/">
                        Methodology
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/methodology/#get-started">
                         - Getting started
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/methodology/#lay-foundations">
                         - Laying foundations
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/methodology/#road-ahead">
                         - The road ahead
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/methodology/#move-forward">
                         - Moving forward
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/methodology/#stay-on-track">
                         - Staying on track
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'services' ? 'active' : '' }>
                      <span className="mr-1">Services</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                    <MDBDropdownItem href="/services/">
                        Services
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/#project-management">
                         - Project management
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/#consultancy">
                         - Consultancy
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/#design">
                         - Design
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/#implementation">
                         - Implementation
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/#support">
                         - Support
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'solutions' ? 'active' : '' }>
                      <span className="mr-1">Solutions</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                      <MDBDropdownItem href="/solutions/">
                        Solutions
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/solutions/digital-experience/">
                         - Digital experience
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/solutions/enterprise-architecture/">
                         - Enterprise architecture
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/solutions/automated-operations/">
                         - Automated operations
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/solutions/digital-delivery/">
                         - Digital delivery
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/solutions/business-agility/">
                         - Business agility
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'corporate-financing' ? 'active' : '' }>
                      <span className="mr-1">Financing</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                      <MDBDropdownItem href="/corporate-financing/">
                        Corporate financing
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/corporate-financing/#macroeconomic-technology-vision">
                         - Macroeconomic technology vision
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/corporate-financing/#mergers-and-acquisitions">
                         - Mergers and acquisitions
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/corporate-financing/#joint-ventures">
                         - Joint ventures
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/corporate-financing/#special-purpose-acquisition-companies">
                         - Special-purpose acquisition companies
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/corporate-financing/#initial-public-offerings">
                         - Initial public offerings
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'lab' ? 'active' : '' }>
                      <span className="mr-1">Lab</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                      <MDBDropdownItem href="/lab/">
                        Lab
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/lab/#physical">
                         - Physical innovation centers
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/lab/#virtual">
                         - Virtual innovation center
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ (this.state.parentNav === 'about' || this.state.parentNav === 'contact' || this.state.parentNav === 'news') ? 'active' : '' }>
                      <span className="mr-1">Company</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-usi">
                      <MDBDropdownItem href="/about/">
                        About UNICOM Global
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/news/">
                        Newsroom
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/contact/">
                        Contact us
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <Link to="/search/" className="nav-link d-none d-lg-block" aria-label="Search">
                    <FontAwesomeIcon icon={faSearch} />
                  </Link>
                  <Link to="/search/" className="nav-link d-lg-none d-sm-block" aria-label="Search">
                    Search
                  </Link>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        {this.state.collapsed && overlay}
      </div>
    )
  }
}

export default NavBarPage
