export function responsiveImage(srcSet) {
    let sizes = []
    let arr = srcSet.split(",")
    arr.map(string => 
        sizes.push(parseInt(string.substring(string.indexOf(" ")+1, string.length-1)))
    )

    let size = sizes.filter(function(x, y) {
        return typeof window !== "undefined" ? x >= window.innerWidth : x >= 1920
    })

    let image = size.length >= 1 ? arr[sizes.indexOf(Math.min.apply(Math, size))] : arr[arr.length]
    return image.substring(0, image.indexOf(" ")).trim()
}

export function isMobile() {
    return typeof window !== "undefined" ? (window.innerWidth < 768) : false
}

export function formatSet(images) {
    let arr = images.split(",")
    if (arr.length > 1) {
        arr.splice(1, arr.length-2)
    }
    return arr.map((string, index) => 
        ` url('${string.substring(0, string.indexOf(" "))}') ${index+1}x` 
        ).toString()
}

export function properCase(str) {
    return str
      .split('-')
      .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
      .join(' ')
  }