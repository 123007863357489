import React from 'react'
import { Link } from 'gatsby'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons'

const today = new Date()

const Footer = () => {
  return (
    <MDBFooter className="usi-footer">
      <MDBContainer>
        <MDBRow className="pt-80 pb-5">
          <MDBCol lg="3" md="6" className="b-3 footer-col">
            <p className="footer-head">UNICOM divisions</p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/unicom-global/divisions/" state={{btn: "Enterprise software"}} >Enterprise software</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/unicom-global/divisions/" state={{btn: "IT infrastructure"}}>IT infrastructure</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/unicom-global/divisions/" state={{btn: "Government tech"}}>Government technology</Link>            
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/unicom-global/divisions/" state={{btn: "Real estate/finance"}}>Finance &amp; real estate</Link>            
            </p>
          </MDBCol>

          <hr className="clearfix w-100 d-md-none pb-3" />

          <MDBCol lg="3" md="6" className="b-3 footer-col">
            <p className="footer-head">Useful links</p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/about/">About</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/contact/">Contact</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/news/">Newsroom</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/search/">Search</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/sitemap/">Sitemap</Link>
            </p>
          </MDBCol>

          <hr className="clearfix w-100 d-lg-none pb-3" />

          <MDBCol lg="3" md="6" className="b-3 footer-col">
            <p className="footer-head">Privacy &amp; legal</p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/privacy-statement/">Privacy</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/cookie-policy/">Cookies</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small pb-3">
              <Link to="/legal-notice/">Legal</Link>
            </p>
            <p className="font-w-400 letter-spacing-1 text-gray-dark text-small">
              <Link to="/do-not-sell-my-personal-information/">Do not sell my personal information</Link>
            </p>
          </MDBCol>

          <hr className="clearfix w-100 d-md-none pb-3" />

          <MDBCol lg="3" md="6" className="b-3 footer-col">
            <p className="footer-head">Social media</p>
            <ul className="list-inline">
              <li>
                <a href="https://www.linkedin.com/company/unicom-global" target="_blank" aria-label="LinkedIn" rel="noopener">
                <FontAwesomeIcon icon={faLinkedinIn} className="fa-2x mx-2 mr-md-2"/>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/unicomglobal" target="_blank" aria-label="Twitter" rel="noopener">
                <FontAwesomeIcon icon={faXTwitter} className="fa-2x mx-2 mr-md-2"/>
                </a>
              </li>
              <li>
                <a href="https://facebook.com/unicomglobal" target="_blank" aria-label="Facebook" rel="noopener">
                <FontAwesomeIcon icon={faFacebookF} className="fa-2x mx-2 mr-md-2"/>
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>

        <MDBRow className="align-items-center pb-60">
          <MDBCol className="text-left">
            <span
              className="font-w-700 letter-spacing-1 text-white text-extra-small text-uppercase"
            >
              © {today.getFullYear()} UNICOM<sup>&reg;</sup> Global &nbsp;&nbsp; | &nbsp;&nbsp; All rights reserved.
            </span>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  )
}
export default Footer