import React, { Component } from 'react'

class NavLogo extends Component {
render() {
    return (  
        <svg width="230.233" height="41" viewBox="0 0 898.5 160" alt="UNICOM Digital logo">
            <style>
                {`.st0{fill:#FFFFFF}`}
                {`.st1{fill:#0095C9}`}
            </style>
        <g>
            <path className="st0" d="M179,20.8h22v43.8c0,4.3-0.6,8.5-1.9,12.4c-1.3,3.8-3.4,7.3-6.2,10s-5.8,4.9-9,6c-4.3,1.7-9.4,2.6-15.4,2.6
                c-3.4,0-7.3-0.2-11.3-0.6c-4.1-0.4-7.5-1.5-10.3-3s-5.3-3.6-7.7-6.4c-2.4-2.8-3.8-5.6-4.7-8.5c-1.3-4.7-2.1-8.8-2.1-12.4V20.8h22
                v44.9c0,4.1,1.1,7.1,3.2,9.4s5.1,3.4,9,3.4s6.8-1.1,9-3.4c2.1-2.1,3.2-5.3,3.2-9.4V20.8C178.8,20.8,179,20.8,179,20.8z"/>
            <path className="st0" d="M216,20.8h20.7l26.9,40.6V20.8h20.9v73.5h-20.9l-26.7-40.4v40.4H216L216,20.8L216,20.8z"/>
            <path className="st0" d="M300,20.8h22.2v73.5H300V20.8z"/>
            <path className="st0" d="M385.7,64.4l19.4,6c-1.3,5.6-3.4,10.3-6.2,13.9c-2.8,3.6-6.2,6.6-10.5,8.5c-4.1,1.9-9.4,2.8-15.8,2.8
                c-7.7,0-14.1-1.1-19-3.4s-9.2-6.4-12.8-12.2c-3.6-5.8-5.3-13.3-5.3-22.4c0-12.2,3.2-21.6,9.4-28s15.2-9.8,26.7-9.8
                c9,0,16,1.9,21.2,5.6c5.1,3.8,9,9.4,11.5,17.3l-19.4,4.5c-0.6-2.4-1.3-3.8-2.1-4.9c-1.3-1.7-2.8-3-4.5-4.1c-1.7-0.9-3.8-1.5-6-1.5
                c-4.9,0-8.8,2.1-11.5,6.2c-1.9,3-3,7.9-3,14.5c0,8.1,1.3,13.7,3.6,16.7c2.4,3,5.8,4.5,10.3,4.5s7.5-1.3,9.6-3.6
                C383.1,72.5,384.6,68.9,385.7,64.4L385.7,64.4z"/>
            <path className="st0" d="M413,57.8c0-12,3.2-21.4,9.8-28s15.6-10,27.1-10s21.2,3.2,27.6,9.8s9.6,15.8,9.6,27.6c0,8.5-1.5,15.6-4.3,21.2
                c-2.8,5.6-6.8,9.8-12.2,12.8s-12,4.7-19.9,4.7s-14.7-1.3-20.1-3.8s-9.6-6.8-12.8-12.6C414.5,73.4,413,66.3,413,57.8z M435.1,57.8
                c0,7.5,1.3,12.8,4.1,16c2.8,3.2,6.4,4.9,10.9,4.9s8.3-1.5,11.1-4.7c2.6-3.2,3.8-8.8,3.8-17.1c0-6.8-1.3-12-4.1-15.2
                c-2.8-3.2-6.4-4.7-11.1-4.7s-8.1,1.7-10.7,4.9C436.3,44.9,435.1,50.3,435.1,57.8L435.1,57.8z"/>
            <path className="st0" d="M498.7,20.8h29.1l11.1,44.7L550,20.8h29.3v73.5h-18.2v-56l-13.9,56h-16.5l-13.9-56v56h-18.2L498.7,20.8
                L498.7,20.8z"/>
        </g>
        <path className="st0" d="M597.3,21.8c1.3,1.1,1.7,2.6,1.7,4.3c0,1.7-0.4,3-1.7,4.1s-2.6,1.7-4.5,1.7c-1.7,0-3.2-0.6-4.5-1.7
            s-1.7-2.6-1.7-4.1s0.6-3,1.7-4.1s2.6-1.7,4.5-1.7C594.5,20.1,596,20.8,597.3,21.8z M596.4,22.5c-1.1-0.9-2.1-1.5-3.6-1.5
            c-1.3,0-2.6,0.4-3.4,1.5c-1.1,0.9-1.5,2.1-1.5,3.4s0.4,2.6,1.5,3.4c1.1,0.9,2.1,1.5,3.4,1.5c1.5,0,2.6-0.4,3.6-1.5
            c1.1-0.9,1.5-2.1,1.5-3.4S597.3,23.6,596.4,22.5z M590.2,22.7h3c0.9,0,1.3,0.2,1.7,0.4c0.4,0.4,0.6,0.9,0.6,1.3
            c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.2-0.9,0.4-1.3,0.4l2.1,3h-1.5l-1.9-3h-1.1v3H590L590.2,22.7L590.2,22.7L590.2,22.7z M591.3,25.5h1.5
            c0.4,0,0.9,0,1.1-0.2c0.2-0.2,0.4-0.4,0.4-0.9c0-0.4-0.2-0.6-0.4-0.6c-0.2-0.2-0.6-0.2-1.1-0.2h-1.5
            C591.3,23.6,591.3,25.5,591.3,25.5z"/>
        <path className="st0" d="M815.5,94.9c-11.3,1.3-19-5.6-19-18.4V52.4h-6.6v-7.9h6.6V33.8h7.9v10.7h10.9v7.9h-10.9v24.2
            c0,9,5.3,11.1,11.1,10.7L815.5,94.9L815.5,94.9L815.5,94.9z"/>
        <path className="st0" d="M857.6,89.2c-3.4,4.5-9.2,7.1-16.7,7.1c-10,0-18.8-4.7-18.8-15.6s7.3-15,19.7-15.8c11.8-0.6,15.6-2.8,15.6-6.8
            s-3.6-7.1-11.8-7.1c-5.1,0-11.8,1.7-12.6,9h-8.3c0.6-10,8.5-16.7,20.9-16.7c13,0,19.4,6.2,19.4,15.6v27.8c0,2.6,0.6,6.2,1.5,8.1
            h-7.9C858,93.5,857.6,91.5,857.6,89.2L857.6,89.2z M857.4,67.8c-3.2,3-9,3.6-14.7,4.1c-10.5,0.6-12.8,4.5-12.8,8.8
            c0,5.6,5.1,7.9,11.3,7.9c8.3,0,16-3.6,16.2-12.8V67.8L857.4,67.8z"/>
        <path className="st0" d="M885.6,94.9h-7.9V22.3h7.9V94.9z"/>
        <path className="st0" d="M627.8,26.8h28.2c8.8,0,15.6,3,20.7,9.2s7.7,14.5,7.7,25s-2.6,18.8-7.9,25c-5.3,6.2-12.4,9.2-21.4,9.2h-27.4
            L627.8,26.8L627.8,26.8L627.8,26.8z M637,87.3h16.9c6.6,0,11.8-2.4,15.4-6.8c3.6-4.5,5.3-11.1,5.3-19.4s-1.7-15-5.1-19.4
            c-3.4-4.5-8.3-6.8-14.7-6.8H637L637,87.3L637,87.3z"/>
        <path className="st0" d="M758.9,91.3c0,16-10,26.9-24.8,26.9c-11.3,0-20.3-6.4-22.7-16.5h8.3c3.2,7.5,10.9,8.5,14.3,8.5
            c12.4,0,16.7-9.8,16.7-19V86c-3.4,4.9-9.4,8.8-16.2,8.8c-13.7,0-23.9-11.8-23.9-26.3s10.3-26.3,23.9-26.3c7.1,0,13.3,4.1,16.7,9.2
            v-7.9h7.7L758.9,91.3L758.9,91.3L758.9,91.3z M734.5,50.1c-9,0-16,8.3-16,18.8s7.1,18.6,16,18.6c9,0,16.2-8.3,16.2-18.6
            C751,58.4,743.5,50.1,734.5,50.1z"/>
        <path className="st0" d="M778.7,36h-7.9v-9.8h7.9V36z M778.7,93.5h-7.9V43.7h7.9V93.5z"/>
        <path className="st0" d="M758.9,91.3c0,16-10,26.9-24.8,26.9c-11.3,0-20.3-6.4-22.7-16.5h8.3c3.2,7.5,10.9,8.5,14.3,8.5
            c12.4,0,16.7-9.8,16.7-19V86c-3.4,4.9-9.4,8.8-16.2,8.8c-13.7,0-23.9-11.8-23.9-26.3s10.3-26.3,23.9-26.3c7.1,0,13.3,4.1,16.7,9.2
            v-7.9h7.7L758.9,91.3L758.9,91.3L758.9,91.3z M734.5,50.1c-9,0-16,8.3-16,18.8s7.1,18.6,16,18.6c9,0,16.2-8.3,16.2-18.6
            C751,58.4,743.5,50.1,734.5,50.1z"/>
        <path className="st0" d="M778.7,36h-7.9v-9.8h7.9V36z M778.7,93.5h-7.9V43.7h7.9V93.5z"/>
        <path className="st0" d="M701.6,35.5h-7.9v-9.8h7.9V35.5z M701.6,93h-7.9V43h7.9V93z"/>
        <path className="st0" d="M701.6,35.5h-7.9v-9.8h7.9V35.5z M701.6,93h-7.9V43h7.9V93z"/>
        <g>
            <path className="st1" d="M59.9,53.9c-0.2-0.2-0.4-0.2-0.9-0.4c-3-1.7-6.4-2.1-9.8-1.5h-0.4c-3.4,0.9-6.4,3-8.5,6.2l-0.2,0.2
                c-1.9,3.2-2.4,6.8-1.7,10.3c0.2,0.9,0.4,1.7,0.9,2.6l0,0c0,0.2,0.2,0.4,0.2,0.6c1.3,5.3,0,10.9-3,15.6c-3,4.7-7.9,7.9-13,9h-2.6
                l-2.8,0.9l0,0l-0.4,0.2c-0.6,0.4-1.3,0.6-1.7,0.9c-0.2,0.2-0.4,0.4-0.6,0.4c-0.4,0.4-0.9,0.6-1.3,1.1l0,0c-0.2,0.2-0.4,0.4-0.4,0.4
                c-0.6,0.6-1.1,1.3-1.5,1.9c-1.9,3.2-2.6,6.8-1.9,10.7c0.9,3.6,3,6.8,6.2,8.8c6.6,4.1,15.2,2.1,19.4-4.3c0.4-0.6,0.9-1.5,1.1-2.4
                c0-0.2,0.2-0.4,0.2-0.6v-0.2c0.2-0.6,0.4-1.1,0.4-1.7c0-0.2,0.2-0.6,0.2-0.9c0-0.6,0.2-1.1,0.2-1.7v-0.6l-0.4-3
                c0-0.4-0.2-0.9-0.4-1.3v-0.2c0-0.1-0.1-0.3-0.2-0.4v-0.2l0,0v-0.2c0-0.2-0.2-0.4-0.2-0.6c-1.3-5.3-0.2-10.9,3-15.6
                c3-4.7,7.9-7.9,13-9l0,0c4.5-0.2,9-2.4,11.5-6.6c1.9-3.2,2.6-6.8,1.9-10.7c-0.4-1.7-1.1-3.4-2.1-4.9C62.9,56.3,61.7,55,59.9,53.9
                L59.9,53.9z"/>
            <path className="st1" d="M43.3,99.9v0.9c0,3.4,1.3,6.6,3.6,9.2l0.2,0.2c2.6,2.6,6,4.1,9.6,4.3h0.4c3.6,0,7.1-1.3,9.8-3.6
                c0.6-0.6,1.3-1.3,1.9-2.1l0,0c0.2-0.2,0.4-0.4,0.4-0.4c4.1-3.6,9.4-5.6,15-5.3s10.7,2.8,14.3,6.8v0.4c0,0.2,0.2,0.2,0.2,0.4
                s0.2,0.2,0.2,0.4l0.2,0.4c0.2,0.2,0.2,0.4,0.4,0.6l2.1,1.9l0,0l0.4,0.2c0.6,0.4,1.1,0.6,1.5,0.9c0.2,0.2,0.4,0.2,0.9,0.4
                c0.4,0.2,1.1,0.4,1.7,0.6l0,0c0.2,0,0.4,0.2,0.6,0.2c0.9,0.2,1.7,0.4,2.6,0.4c3.8,0.2,7.3-1.1,10-3.6s4.3-6,4.5-9.8
                c0.2-7.7-5.8-14.3-13.5-14.5c-0.9,0-1.5,0-2.6,0.2c-0.2,0-0.4,0-0.6,0.2h-0.2c-0.6,0.2-1.1,0.2-1.7,0.4c-0.2,0-0.6,0.2-0.9,0.2
                c-0.4,0.2-1.1,0.4-1.7,0.9c-0.2,0-0.2,0.2-0.4,0.2h-0.2l-1.7,1.7l-0.9,0.9l-0.2,0.2c0,0.2-0.2,0.2-0.2,0.2l-0.2,0.2l0,0l-0.4,0.4
                c-4.1,3.6-9.4,5.6-15,5.3C78.1,99.6,73,97.1,69.4,93l0,0c-2.4-3.8-6.6-6.6-11.3-6.8c-3.8-0.2-7.3,1.1-10,3.6
                c-1.3,1.3-2.4,2.6-3.2,4.3C43.7,96,43.3,97.7,43.3,99.9z"/>
            <path className="st1" d="M91.6,91.5c0.2-0.2,0.4-0.2,0.9-0.4c3-1.7,5.1-4.5,6.2-7.9v-0.4c1.1-3.4,0.6-7.1-1.1-10.5l-0.2-0.2
                c-1.7-3.2-4.7-5.6-8.1-6.6c-0.9-0.2-1.7-0.4-2.8-0.6l0,0c-0.2,0-0.4-0.2-0.6-0.2c-5.1-1.7-9.6-5.3-12-10.3
                c-2.6-4.9-3-10.7-1.3-15.8v-0.4c0-0.2,0.2-0.2,0.2-0.4s0-0.2,0.2-0.4l0.2-0.4c0.2-0.2,0.2-0.4,0.2-0.6l0.6-2.8l0,0v-3.2
                c0-0.6-0.2-1.1-0.2-1.7l0,0c0-0.2-0.2-0.4-0.2-0.6c-0.2-0.9-0.6-1.7-0.9-2.4c-1.7-3.4-4.7-5.8-8.3-6.8c-3.6-1.1-7.5-0.9-10.7,1.1
                c-6.8,3.6-9.6,12.2-6,19c0.4,0.6,0.9,1.3,1.5,2.1c0.2,0.2,0.2,0.4,0.4,0.4l0,0c0.4,0.4,0.9,0.9,1.3,1.3c0.2,0.2,0.4,0.4,0.6,0.6
                c0.4,0.4,0.9,0.6,1.5,1.1c0.2,0,0.2,0.2,0.4,0.2c0,0,0.2,0,0.2,0.2l3,1.1c0.4,0,0.9,0.2,1.3,0.2h1.1c0.2,0,0.4,0.2,0.6,0.2
                c5.1,1.7,9.6,5.3,12,10.3c2.6,4.9,3,10.7,1.3,15.8l0,0c-2.1,4.1-2.4,9-0.2,13.3c1.7,3.4,4.7,5.8,8.3,6.8c1.7,0.6,3.4,0.6,5.1,0.6
                C87.9,93,89.9,92.4,91.6,91.5L91.6,91.5z"/>
        </g>
        <g>
            <path className="st0" d="M145.7,139.6v-24.4h-9V112h21.6v3.2h-9.2v24.4H145.7z"/>
            <path className="st0" d="M160.2,139.6v-19.9h3v3c0.9-1.5,1.5-2.4,2.1-2.8c0.6-0.4,1.3-0.6,2.1-0.6c1.1,0,2.4,0.4,3.4,1.1l-1.1,3.2
                c-0.9-0.4-1.7-0.6-2.6-0.6c-0.6,0-1.5,0.2-1.9,0.6c-0.4,0.4-1.1,1.1-1.3,1.9c-0.4,1.3-0.6,2.6-0.6,3.8v10.5h-3.2
                C160.2,139.8,160.2,139.6,160.2,139.6z"/>
            <path className="st0" d="M186.1,137.1c-1.3,1.1-2.4,1.7-3.6,2.4c-1.3,0.6-2.4,0.6-3.6,0.6c-2.1,0-3.8-0.4-5.1-1.7
                c-1.3-1.1-1.7-2.4-1.7-4.1c0-1.1,0.2-1.9,0.6-2.8c0.4-0.9,1.1-1.5,1.7-1.9s1.5-0.9,2.4-1.1c0.6-0.2,1.7-0.4,3-0.4
                c2.8-0.4,4.7-0.6,6-1.1v-0.9c0-1.3-0.2-2.4-0.9-3c-0.9-0.9-2.1-1.1-3.8-1.1c-1.5,0-2.8,0.2-3.4,0.9s-1.3,1.5-1.7,3l-3.4-0.6
                c0.2-1.5,0.9-2.6,1.5-3.4c0.6-0.9,1.7-1.5,3-1.9c1.3-0.4,2.8-0.6,4.5-0.6c1.7,0,3,0.2,4.1,0.6c1.1,0.4,1.9,0.9,2.4,1.5
                s0.9,1.3,1.1,2.4c0.2,0.6,0.2,1.7,0.2,3.2v4.5c0,3.2,0,5.1,0.2,6s0.4,1.7,0.9,2.4h-3.6C186.3,139,186.1,138.1,186.1,137.1z
                M185.8,129.6c-1.3,0.4-3,0.9-5.6,1.3c-1.5,0.2-2.4,0.4-3,0.6s-1.1,0.6-1.3,1.1c-0.2,0.4-0.4,1.1-0.4,1.7c0,0.9,0.4,1.7,1.1,2.4
                c0.6,0.6,1.7,0.9,3,0.9c1.3,0,2.6-0.2,3.4-0.9s1.7-1.3,2.1-2.4c0.4-0.9,0.6-1.9,0.6-3.4C185.8,130.9,185.8,129.6,185.8,129.6z"/>
            <path className="st0" d="M194.4,139.6v-19.9h3v2.8c1.5-2.1,3.6-3.2,6.4-3.2c1.3,0,2.4,0.2,3.4,0.6c1.1,0.4,1.7,1.1,2.4,1.7
                c0.6,0.6,0.9,1.5,1.1,2.6c0.2,0.6,0.2,1.7,0.2,3.2v12.2h-3.4v-12.2c0-1.3-0.2-2.4-0.4-3c-0.2-0.6-0.6-1.3-1.5-1.7
                c-0.6-0.4-1.5-0.6-2.4-0.6c-1.5,0-2.8,0.4-3.6,1.3c-0.9,0.9-1.5,2.6-1.5,5.1v10.9h-3.6L194.4,139.6L194.4,139.6z"/>
            <path className="st0" d="M214.5,133.6l3.4-0.4c0.2,1.3,0.6,2.4,1.5,3s2.1,1.1,3.6,1.1c1.5,0,2.8-0.2,3.4-0.9c0.6-0.6,1.1-1.3,1.1-2.1
                s-0.4-1.3-1.1-1.7c-0.4-0.2-1.7-0.6-3.4-1.1c-2.6-0.6-4.3-1.1-5.1-1.7c-0.9-0.6-1.7-1.1-2.1-1.9c-0.4-0.9-0.6-1.7-0.6-2.8
                c0-0.9,0.2-1.7,0.6-2.6c0.4-0.9,0.9-1.3,1.7-1.9c0.4-0.4,1.3-0.6,2.1-1.1c0.9-0.4,1.9-0.4,3-0.4c1.5,0,3,0.2,4.1,0.6
                c1.1,0.4,2.1,1.1,2.6,1.9c0.6,0.9,0.9,1.7,1.1,3l-3.2,0.4c-0.2-1.1-0.6-1.9-1.3-2.4s-1.7-0.9-3-0.9c-1.5,0-2.6,0.2-3.4,0.9
                c-0.9,0.6-1.1,1.1-1.1,1.7c0,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.6,0.6,1.3,0.9c0.4,0.2,1.3,0.4,3,0.9c2.4,0.6,4.1,1.1,4.9,1.5
                c0.9,0.4,1.7,1.1,2.1,1.7s0.9,1.7,0.9,3c0,1.1-0.4,2.1-1.1,3.2s-1.7,1.7-2.8,2.4c-1.3,0.6-2.6,0.9-4.3,0.9c-2.6,0-4.5-0.6-6-1.7
                S214.9,135.8,214.5,133.6z"/>
            <path className="st0" d="M235.9,139.6v-17.3h-3v-2.6h3v-2.1c0-1.3,0.2-2.4,0.4-3c0.4-0.9,0.9-1.5,1.7-2.1c0.9-0.6,1.9-0.9,3.4-0.9
                c0.9,0,1.9,0.2,3.2,0.4l-0.4,3c-0.6-0.2-1.3-0.2-1.9-0.2c-1.1,0-1.7,0.2-2.1,0.6c-0.4,0.4-0.6,1.3-0.6,2.6v1.9h3.8v2.6h-3.8v17.3
                h-3.6C235.9,139.8,235.9,139.6,235.9,139.6z"/>
            <path className="st0" d="M244.4,129.6c0-3.6,1.1-6.4,3-8.1c1.7-1.5,3.8-2.1,6.2-2.1c2.8,0,4.9,0.9,6.6,2.8c1.7,1.9,2.6,4.3,2.6,7.5
                c0,2.6-0.4,4.5-1.1,6c-0.9,1.5-1.9,2.6-3.4,3.4c-1.5,0.9-3,1.3-4.9,1.3c-2.8,0-5.1-0.9-6.8-2.8C244.8,135.6,244.4,133,244.4,129.6z
                M248,129.6c0,2.6,0.6,4.5,1.7,5.8c1.1,1.3,2.6,1.9,4.3,1.9c1.7,0,3-0.6,4.3-1.9c1.3-1.3,1.7-3.2,1.7-5.8c0-2.6-0.6-4.3-1.7-5.6
                c-1.1-1.3-2.6-1.9-4.3-1.9c-1.7,0-3,0.6-4.3,1.9C248.5,125.3,248,127,248,129.6z"/>
            <path className="st0" d="M267.1,139.6v-19.9h3v3c0.9-1.5,1.5-2.4,2.1-2.8s1.3-0.6,2.1-0.6c1.1,0,2.4,0.4,3.4,1.1l-1.1,3.2
                c-0.9-0.4-1.7-0.6-2.6-0.6c-0.6,0-1.5,0.2-1.9,0.6c-0.4,0.4-1.1,1.1-1.3,1.9c-0.4,1.3-0.6,2.6-0.6,3.8v10.5h-3.2
                C267.1,139.8,267.1,139.6,267.1,139.6z"/>
            <path className="st0" d="M279.9,139.6v-19.9h3v2.8c0.6-1.1,1.5-1.7,2.6-2.4c1.1-0.6,2.1-0.9,3.6-0.9s2.8,0.2,3.6,0.9
                c0.9,0.6,1.7,1.5,1.9,2.6c1.5-2.4,3.6-3.4,6.2-3.4c1.9,0,3.4,0.6,4.5,1.7s1.5,2.8,1.5,5.1v13.7h-3.2v-12.6c0-1.3-0.2-2.4-0.4-3
                c-0.2-0.6-0.6-1.1-1.3-1.5s-1.3-0.6-1.9-0.6c-1.5,0-2.6,0.4-3.4,1.5c-0.9,0.9-1.5,2.4-1.5,4.5v11.5h-3.4v-13c0-1.5-0.2-2.6-0.9-3.4
                c-0.6-0.9-1.5-1.1-2.8-1.1c-0.9,0-1.9,0.2-2.6,0.9c-0.6,0.6-1.5,1.3-1.7,2.1c-0.2,0.9-0.6,2.4-0.6,4.3v10.3L279.9,139.6
                L279.9,139.6z"/>
            <path className="st0" d="M330.1,136.6l0.4,3c-0.9,0.2-1.7,0.2-2.6,0.2c-1.3,0-2.1-0.2-2.8-0.6c-0.6-0.4-1.1-0.9-1.5-1.5
                s-0.4-1.9-0.4-4.1v-11.5h-2.6v-2.6h2.6v-4.9l3.4-1.9v7.1h3.4v2.6h-3.4v11.5c0,1.1,0,1.5,0.2,1.9s0.2,0.4,0.6,0.6s0.6,0.2,1.1,0.2
                H330.1L330.1,136.6z"/>
            <path className="st0" d="M333.3,139.6V112h3.4v9.8c1.5-1.9,3.6-2.8,6-2.8c1.5,0,2.8,0.2,3.8,0.9c1.1,0.6,1.9,1.3,2.4,2.4
                c0.4,1.1,0.6,2.6,0.6,4.5v12.6h-3.4v-12.6c0-1.7-0.4-3-1.1-3.6c-0.6-0.9-1.7-1.1-3.2-1.1c-1.1,0-1.9,0.2-2.8,0.9
                c-0.9,0.4-1.5,1.3-1.9,2.1c-0.4,0.9-0.6,2.1-0.6,3.6v10.9L333.3,139.6L333.3,139.6z"/>
            <path className="st0" d="M368.4,133.2l3.4,0.4c-0.6,2.1-1.5,3.6-3,4.7c-1.5,1.1-3.4,1.7-5.8,1.7c-3,0-5.1-0.9-6.8-2.8
                c-1.7-1.7-2.6-4.3-2.6-7.5c0-3.4,0.9-6,2.6-7.9c1.7-1.9,4.1-2.8,6.6-2.8c2.6,0,4.9,0.9,6.6,2.8c1.7,1.9,2.6,4.3,2.6,7.7v0.9h-15
                c0.2,2.1,0.6,3.8,1.9,5.1c1.3,1.3,2.6,1.7,4.3,1.7c1.3,0,2.4-0.4,3.2-1.1C367.3,135.6,367.9,134.7,368.4,133.2L368.4,133.2z
                M357.3,127.6h11.1c-0.2-1.7-0.6-3-1.3-3.8c-1.1-1.3-2.6-1.9-4.3-1.9c-1.5,0-2.8,0.4-3.8,1.5C357.9,124.4,357.5,125.9,357.3,127.6
                L357.3,127.6z"/>
            <path className="st0" d="M390.6,139.6l-6.2-19.9h3.4l3.2,11.5l1.3,4.3c0-0.2,0.4-1.5,1.1-4.1l3.2-11.8h3.4l3,11.5l1.1,3.8l1.1-3.8
                l3.4-11.5h3.2l-6.2,19.9h-3.4l-3.2-12l-0.9-3.4l-4.1,15.4H390.6L390.6,139.6z"/>
            <path className="st0" d="M427.6,137.1c-1.3,1.1-2.6,1.7-3.6,2.4c-1.1,0.4-2.4,0.6-3.6,0.6c-2.1,0-3.8-0.4-5.1-1.7
                c-1.3-1.1-1.7-2.4-1.7-4.1c0-1.1,0.2-1.9,0.6-2.8c0.4-0.9,1.1-1.5,1.7-1.9s1.5-0.9,2.4-1.1c0.6-0.2,1.7-0.4,3-0.4
                c2.8-0.4,4.7-0.6,6-1.1v-0.9c0-1.3-0.2-2.4-0.9-3c-0.9-0.9-2.1-1.1-3.8-1.1c-1.5,0-2.8,0.2-3.4,0.9s-1.3,1.5-1.7,3l-3.2-0.4
                c0.2-1.5,0.9-2.6,1.5-3.4s1.7-1.5,3-1.9c1.3-0.4,2.8-0.6,4.5-0.6s3,0.2,4.1,0.6s1.9,0.9,2.4,1.5c0.4,0.6,0.9,1.3,1.1,2.4
                c0.2,0.6,0.2,1.7,0.2,3.2v4.5c0,3.2,0,5.1,0.2,6c0.2,0.9,0.4,1.7,0.9,2.4h-3.6C428,139,427.8,138.1,427.6,137.1L427.6,137.1z
                M427.4,129.6c-1.3,0.4-3,0.9-5.6,1.3c-1.5,0.2-2.4,0.4-3,0.6s-1.1,0.6-1.3,1.1s-0.4,1.1-0.4,1.7c0,0.9,0.4,1.7,1.1,2.4
                s1.7,0.9,3,0.9c1.3,0,2.6-0.2,3.4-0.9s1.7-1.3,2.1-2.4c0.4-0.9,0.6-1.9,0.6-3.4V129.6z"/>
            <path className="st0" d="M435.9,147.3l-0.4-3.2c0.6,0.2,1.3,0.2,1.9,0.2c0.9,0,1.3-0.2,1.7-0.4c0.4-0.2,0.9-0.6,1.1-1.1
                s0.6-1.3,1.1-2.6c0-0.2,0.2-0.4,0.2-0.9l-7.5-20.1h3.6l4.1,11.5c0.6,1.5,1.1,3,1.5,4.7c0.4-1.5,0.9-3,1.5-4.5l4.3-11.5h3.4
                l-7.7,20.3c-0.9,2.1-1.5,3.6-1.9,4.5c-0.6,1.1-1.3,1.9-2.1,2.4c-0.9,0.4-1.7,0.9-2.8,0.9C437.4,147.7,436.8,147.5,435.9,147.3z"/>
            <path className="st0" d="M465.8,147.3l-0.4-3.2c0.6,0.2,1.3,0.2,1.9,0.2c0.9,0,1.3-0.2,1.7-0.4c0.4-0.2,0.9-0.6,1.1-1.1
                c0.2-0.4,0.6-1.3,1.1-2.6c0-0.2,0.2-0.4,0.2-0.9l-7.5-20.1h3.6l4.1,11.5c0.6,1.5,1.1,3,1.5,4.7c0.4-1.5,0.9-3,1.5-4.5l4.3-11.5h3.4
                l-7.7,20.3c-0.9,2.1-1.5,3.6-1.9,4.5c-0.6,1.1-1.3,1.9-2.1,2.4c-0.9,0.4-1.7,0.9-2.8,0.9C467.3,147.7,466.7,147.5,465.8,147.3z"/>
            <path className="st0" d="M484,129.6c0-3.6,1.1-6.4,3-8.1c1.7-1.5,3.8-2.1,6.2-2.1c2.8,0,4.9,0.9,6.6,2.8c1.7,1.9,2.6,4.3,2.6,7.5
                c0,2.6-0.4,4.5-1.1,6c-0.9,1.5-1.9,2.6-3.4,3.4c-1.5,0.9-3,1.3-4.9,1.3c-2.8,0-5.1-0.9-6.8-2.8C484.9,135.6,484,133,484,129.6
                L484,129.6z M487.4,129.6c0,2.6,0.6,4.5,1.7,5.8c1.1,1.3,2.6,1.9,4.3,1.9c1.7,0,3-0.6,4.3-1.9c1.3-1.3,1.7-3.2,1.7-5.8
                c0-2.6-0.6-4.3-1.7-5.6c-1.1-1.3-2.6-1.9-4.3-1.9c-1.7,0-3,0.6-4.3,1.9S487.4,127,487.4,129.6z"/>
            <path className="st0" d="M519.7,139.6v-3c-1.5,2.4-3.6,3.4-6.4,3.4c-1.3,0-2.4-0.2-3.2-0.6s-1.7-1.1-2.4-1.7c-0.4-0.6-0.9-1.5-1.1-2.6
                c-0.2-0.6-0.2-1.7-0.2-3.2v-12.4h3.4v11.1c0,1.7,0,3,0.2,3.6c0.2,0.9,0.6,1.5,1.3,2.1c0.6,0.4,1.5,0.9,2.6,0.9s1.9-0.2,2.8-0.9
                c0.9-0.6,1.5-1.3,1.9-2.1c0.4-0.9,0.6-2.1,0.6-3.8v-10.7h3.4v19.9H519.7L519.7,139.6z"/>
            <path className="st0" d="M542.3,139.6l-6.2-19.9h3.4l3.2,11.5l1.3,4.3c0-0.2,0.4-1.5,1.1-4.1l3.2-11.8h3.4l3,11.5l1.1,3.8l1.1-3.8
                l3.4-11.5h3.2l-6.2,19.9h-3.4l-3.2-12l-0.9-3.4l-4.1,15.4H542.3L542.3,139.6z"/>
            <path className="st0" d="M565.2,129.6c0-3.6,1.1-6.4,3-8.1c1.7-1.5,3.8-2.1,6.2-2.1c2.8,0,4.9,0.9,6.6,2.8c1.7,1.9,2.6,4.3,2.6,7.5
                c0,2.6-0.4,4.5-1.1,6c-0.9,1.5-1.9,2.6-3.4,3.4c-1.5,0.9-3,1.3-4.9,1.3c-2.8,0-5.1-0.9-6.8-2.8C566.1,135.6,565.2,133,565.2,129.6z
                M568.6,129.6c0,2.6,0.6,4.5,1.7,5.8c1.1,1.3,2.6,1.9,4.3,1.9s3-0.6,4.3-1.9c1.3-1.3,1.7-3.2,1.7-5.8c0-2.6-0.6-4.3-1.7-5.6
                c-1.1-1.3-2.6-1.9-4.3-1.9s-3,0.6-4.3,1.9S568.6,127,568.6,129.6z"/>
            <path className="st0" d="M587.9,139.6v-19.9h3v3c0.9-1.5,1.5-2.4,2.1-2.8s1.3-0.6,2.1-0.6c1.1,0,2.4,0.4,3.4,1.1l-1.1,3.2
                c-0.9-0.4-1.7-0.6-2.6-0.6c-0.6,0-1.5,0.2-1.9,0.6c-0.4,0.4-1.1,1.1-1.3,1.9c-0.4,1.3-0.6,2.6-0.6,3.8v10.5h-3.2
                C587.9,139.8,587.9,139.6,587.9,139.6z"/>
            <path className="st0" d="M600.7,139.6V112h3.4v15.6l7.9-8.1h4.3l-7.7,7.5l8.3,12.6h-4.3l-6.6-10.3l-2.4,2.4v7.9H600.7z"/>
        </g>
        <g>
            <path className="st0" d="M620.8,120.6l1.5-0.2c0,0.6,0.2,1.1,0.4,1.5c0.2,0.4,0.6,0.6,1.3,0.9s1.3,0.4,1.9,0.4s1.1,0,1.7-0.2
                s0.9-0.4,1.1-0.9c0.2-0.4,0.4-0.6,0.4-1.1c0-0.4-0.2-0.6-0.4-1.1s-0.6-0.4-1.1-0.6c-0.4-0.2-1.1-0.4-2.1-0.6s-1.9-0.4-2.4-0.9
                c-0.6-0.2-1.1-0.6-1.3-1.1c-0.2-0.4-0.4-1.1-0.4-1.5c0-0.6,0.2-1.3,0.4-1.7c0.2-0.4,0.9-0.9,1.5-1.3c0.6-0.4,1.5-0.4,2.4-0.4
                s1.7,0.2,2.4,0.4c0.6,0.2,1.3,0.6,1.5,1.3c0.2,0.6,0.6,1.3,0.6,1.9l-1.5,0.2c0-0.9-0.4-1.3-0.9-1.7c-0.4-0.4-1.3-0.6-2.1-0.6
                c-1.1,0-1.7,0.2-2.1,0.6c-0.4,0.4-0.6,0.9-0.6,1.3c0,0.4,0.2,0.9,0.4,1.1c0.2,0.2,1.1,0.6,2.4,0.9s2.1,0.6,2.8,0.9
                c0.6,0.4,1.3,0.6,1.5,1.3c0.2,0.6,0.4,1.1,0.4,1.7c0,0.6-0.2,1.3-0.6,1.9c-0.4,0.6-0.9,1.1-1.7,1.3s-1.5,0.4-2.4,0.4
                c-1.1,0-2.1-0.2-2.8-0.4s-1.3-0.9-1.7-1.5S620.8,121.4,620.8,120.6L620.8,120.6z"/>
            <path className="st0" d="M632.8,124.4v-12.2h2.4l3,8.8c0.2,0.9,0.4,1.5,0.6,1.7c0.2-0.4,0.4-1.1,0.6-1.9l3-8.5h2.1v12.2H643v-10.3
                l-3.6,10.3h-1.5l-3.6-10.5v10.5L632.8,124.4L632.8,124.4z"/>
        </g>
        </svg>
    )}
}

export default NavLogo